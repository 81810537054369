<template>
    <div class="card" >
        <div class="percent" :style=" {'--percent': percent }">
            <div class="point"></div>
                <svg>
                    <circle cx="70" cy="70" r="70"></circle>
                    <circle cx="70" cy="70" r="70"></circle>
                </svg>
                <div class="text">
                    <h2>{{percent}}<span>%</span></h2>
                    <p>{{title}}</p>
                </div>
        </div>
    </div>
</template>


<script>
export default {
    name: "CardPercent",
    props:{
        percent: {
          type: Number,
          default: 0
        },
        title: {
          type: String,
          default: ''
        }
    }
}
</script>


<style scoped>
.card {
  position: relative;
  min-width: 220px;
  width: 220px;
  height: 250px;
  background: linear-gradient(
    169.26deg,
    rgba(255, 255, 255, 0.84) 0%,
    rgba(255, 255, 255, 0.15) 100%
  );
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 3px 9px rgb(0 0 0 / 25%), inset 0 0 15px white;
}
.card .percent {
  position: relative;
  width: 150px;
  height: 150px;
}
.card .percent svg {
  position: relative;
  width: 150px;
  height: 150px;
  transform: rotate(270deg);
}
.card .percent svg circle {
  width: 100%;
  height: 100%;
  fill: transparent;
  stroke-width: 3;
  stroke: #110133;
  transform: translate(5px, 5px);
}
.card .percent svg circle:nth-child(2) {
  stroke: #4dd599;
  stroke-dasharray: 440;
  stroke-dashoffset: calc(440 - (440 * var(--percent) / 100));
  animation: fadeIn 1s linear forwards;
  animation-delay: 1.4s;
  opacity: 0;
}
.point {
  position: absolute;
  inset: 5px;
  z-index: 10;
  animation: animatePoint 1.5s linear forwards;
}
.point::before {
  content: "";
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #4dd599;
  box-shadow: 0 0 10px #4dd599, 0 0 30px #4dd599;
}
.text {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  animation: fadeIn 1s linear forwards;
  animation-delay: 1.4s;
  opacity: 0;
}
.text h2 {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #110133;
  font-weight: 700;
  font-size: 2.5em;
}
.text h2 span {
  font-weight: 300;
  font-size: 0.5em;
}
.text p {
  font-weight: 300;
  font-size: 0.75;
  letter-spacing: 2px;
  text-transform: uppercase;
}
@keyframes animatePoint {
  0% {
    transform: rotate(0px);
  }
  100% {
    transform: rotate(calc(3.6deg * var(--percent)));
    /* 360deg / 100 = 3.6deg */
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>