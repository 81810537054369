<template>
    <div class="dashboard-graficas" style="overflow: hidden;">
      <div 
        class="container-grid-movible"
      >
        <div 
          v-for="(elementoGrid, index) in elementosGrid" :key="elementoGrid.id" 
          :class="`subgrid-movible ${elementoGrid.espacio ? 'subgrid-espacio': ''} ${elementoGrid.espacio && isDrag ? 'bg-grey': ''}`"
          draggable="true"
          dropzone="true"
          :data-dataid="elementoGrid.id"
          :data-dataname="elementoGrid.name"
          @dragstart="(event)=>dragStart(event, elementoGrid)"
          @dragenter="(event)=>dragEnter(event, elementoGrid)"
          @dragend="()=>dragEnd()"
          @mouseup="(event)=> mouseUp(event, elementoGrid)"
        >
          <div class="elemento-subgrid-movible" draggable="true" dropzone="true"  v-if="elementoGrid.espacio">
          </div>
          <div class="elemento-subgrid-movible" draggable="true" dropzone="true" v-else>
            <p>nombre: <b>{{elementoGrid.name}}</b></p>
            <p> posicion: <b>{{index}}</b></p>
            <p>id: <b>{{elementoGrid.id}}</b></p>
          </div>
        </div>
      </div>
      <div 
        v-if="isDrag" 
        class="subgrid-movible-clon" 
        :style="`
          width: ${elementoAgarrado.width}; 
          height: ${elementoAgarrado.height};
          position: absolute; 
          top: ${elementoAgarrado.y}px; 
          left: ${elementoAgarrado.x}px;
          transition: none;
          pointer-events:none;
          `
        "

      >
        <div class="elemento-subgrid-movible-clon">
          <p>nombre: <b>{{elementoAgarrado.name}}</b></p>
          <p>posicion: <b>{{posicionId[String(elementoAgarrado.id)]}}</b></p>
          <p>id: <b>{{elementoAgarrado.id}}</b></p>
        </div>
      </div>
  </div>
</template>

<script>
import Footer from "../../components/Footer.vue";
import { mapState } from "vuex";
import CardPercent from "../../components/CardPercent.vue";

export default {
  name: "DashboardGraficas",
  components: {
    Footer,
    CardPercent,
  },
  data() {
    return {
      products: [
        { id: 1, percent: 15, name: "papa"},
        { id: 2, percent: 80, name: "lechuga" },
        { id: 3, percent: 39, name: "tomate" },
        { id: 4, percent: 43, name: "manzana" },
        { id: 5, percent: 25, name: "legumbre" },
        { id: 6, percent: 40, name: "arroz" },
        { id: 7, percent: 49, name: "salchicha" },
        { id: 8, percent: 13, name: "pure" },
        { id: 9, percent: 13, name: "pure", espacio: true},
      ],

      // estados drag and drop
      posicionId: {
        1:0, 2:1, 3:2,
        4:3, 5:4, 6:5,
        7:6, 8:7
      },
      elementosGrid: [],
      elementoAgarradoID: undefined,
      elementoEncimadoID: undefined,
      elementoAgarrado: {
        id: 0,
        name: "nada",
        x: 0,
        y: 0,
        width: "300px",
        height: "300px",
        agarradoDesde: {
          x: 0,
          y: 0
        }
      },
      isDrag: false,


      //estado aun no usados, pero en caso de seguir avanzando
      tamañoDefaultPosicionadores: {
        width: "33,33%",
        height: "33,33%"
      },
      cuadroLimitador: {
        width: "900px",
        height: "900px"
      },
      posicionadores: [
        {
          tamaño: this.tamañoDefaultPosicionadores,
          posicion: 0,
          ubicacion: {
            x: 0,
            y: 0,
          }
        }
      ],      
    };
  },
  methods: {
    dataConsole(data){
      console.log(data)
    },
    drag(event, element){
      console.log("drag",event)
    },
    dragStart(event, element){
      if(element.espacio) {
        event.stopPropagation()        
        event.preventDefault()
        return
      }

      //drag start comienza cuando se agarre un elemento
      event.stopPropagation()

      //buscamos el elemento que queremos ya que a veces se puede agarrar un hijo
      let subgridEncontrado = this.buscarElementoEnEvento({padre: "subgrid-movible", hijo: "elemento-subgrid-movible", evento: event})
      const posicionDeSubGrid = subgridEncontrado.getBoundingClientRect()

      //modificamos el estado del elemento agarrado
      this.elementoAgarrado.agarradoDesde.x = event.x - posicionDeSubGrid.x
      this.elementoAgarrado.agarradoDesde.y = event.y - posicionDeSubGrid.y
      this.elementoAgarradoID = element.id
      this.elementoAgarrado = {...this.elementoAgarrado, ...element}
      this.elementoAgarrado.height = subgridEncontrado.offsetHeight + "px"
      this.elementoAgarrado.width = subgridEncontrado.offsetWidth + "px"

      this.cambiarDiseñoElemento(subgridEncontrado)
      
      this.isDrag = true
    },
    dragEnd(){
      this.isDrag = false
    },
    drop(event){
      event.stopPropagation()
      
      let subgridEncontrado = this.buscarElementoEnEvento({padre: "subgrid-movible", hijo: "elemento-subgrid-movible", evento: event})

      // cuando se dropea se vuelve visible el elemento
      this.volverDiseñoInicialElemento(subgridEncontrado)


      // se reinicia estado elemento agarrado
      this.elementoAgarradoID = 0
      this.elementoAgarrado.agarradoDesde.x = 0
      this.elementoAgarrado.agarradoDesde.y = 0
    
    },
    mouseUp(event,element){
      event.stopPropagation()

      let subgridEncontrado = this.buscarElementoEnEvento({padre: "subgrid-movible", hijo: "elemento-subgrid-movible", evento: event})

      // en caso de que se dropee afuera se ve cuando se levanta el click del mouse y acciona igual que drop
      this.volverDiseñoInicialElemento(subgridEncontrado)

      this.isDrag = false
    },
    dragOver(event,element){
      event.stopPropagation()
      event.preventDefault()
      
      let subgridEncontrado = this.buscarElementoEnEvento({padre: "subgrid-movible", hijo: "elemento-subgrid-movible", evento: event})

      this.cambiarDiseñoElemento(subgridEncontrado)
    },
    // cuando sale de un elemento drag
    dragLeave(event, element){
      event.stopPropagation()
      event.preventDefault()
      let subgridEncontrado = this.buscarElementoEnEvento({padre: "subgrid-movible", hijo: "elemento-subgrid-movible", evento: event})

      this.volverDiseñoInicialElemento(subgridEncontrado)
    },
    // cuando entra a un nuevo elemento drag
    dragEnter(event, element){
      event.stopPropagation()

      let subgridEncontrado = this.buscarElementoEnEvento({padre: "subgrid-movible", hijo: "elemento-subgrid-movible", evento: event})

      this.cambiarDiseñoElemento(subgridEncontrado)

      // se cambia estado de elemento encimado y se cambia de lugares los divs
      const elementoEncimadoID = element.id
      this.elementoEncimadoID = elementoEncimadoID
      this.cambiarPosicionesDeElementos(this.elementoAgarradoID, this.elementoEncimadoID)
    },
    mouseOver(event, element){
      event.stopPropagation()
      const subgridEncontrado = this.buscarElementoEnEvento({padre: "subgrid-movible", hijo: "elemento-subgrid-movible", evento: event})
      const posicionDeSubGrid = subgridEncontrado.getBoundingClientRect()

      const agarradoDesde = this.isDrag ? {x: this.elementoAgarrado.agarradoDesde.x, y: this.elementoAgarrado.agarradoDesde.y} : {
        x: event.x - posicionDeSubGrid.x,
        y: event.y - posicionDeSubGrid.y
      }


      this.elementoAgarrado.x = event.x -agarradoDesde.x
      this.elementoAgarrado.y = event.y - agarradoDesde.y
    },
    volverDiseñoInicialElemento(elemento){
      elemento.style.transition = "all .3s linear, background-color 0s linear, opacity 0s linear"
      elemento.firstChild.style.transition = "all .3s linear"
      elemento.firstChild.style.opacity = 1

      elemento.style.border = ""
      elemento.style.backgroundColor = ""
    },
    cambiarDiseñoElemento(elemento){
      elemento.style.transition = "none"
      elemento.firstChild.style.transition = "none"
      elemento.firstChild.style.opacity = 0
      elemento.style.border = "2px dashed #21252948"
      elemento.style.backgroundColor = "#2125291a"
    },

    /*
    LOGICA DE CAMBIADOR DE POSICIONES DE ELEMENTOS

    Va desde el elemento agarrado hacia el elemento a cambiar.
    Si es menor va hacia la derecha pasando los de derecha hacia la izquierda y sino lo contrario. Al final se pone el elemento a cambiar a su nueva posicion;
    1 hacia 3
    [1, 2, 3] => [2, 2, 3] => [2, 3, 3] => paso final [2, 3, 1]
    
    3 hacia 1
    [1, 2, 3] => [1, 2, 2] => [1, 1, 2] => paso final [3, 1, 2]
    */
    cambiarPosicionesDeElementos(elementoAgarradoID, encimaDeElementoID){
      // si el elemento agarrado es igual al que se le esta por encima no se cambia de posicion
      if(elementoAgarradoID === encimaDeElementoID) return

      // si es diferente entonces tomamos los datos necesarios para la logica
      const posicionDelAgarrado = this.posicionId[elementoAgarradoID]
      const posicionDelEncimado = this.posicionId[encimaDeElementoID]
      const posicionDelAgarradoEsMenor  = posicionDelAgarrado < posicionDelEncimado
      const nuevoElementosGrid = new Array(...this.elementosGrid)
      const elementoAgarrado =  nuevoElementosGrid[posicionDelAgarrado]
      const siguientePosicion =  posicionDelAgarradoEsMenor ? 1 : - 1

      for (let index = posicionDelAgarrado; index !== posicionDelEncimado; posicionDelAgarradoEsMenor ? index++ : index--) {
        const elementoACambiar = nuevoElementosGrid[index + siguientePosicion]
        nuevoElementosGrid[index] = elementoACambiar
        this.posicionId[String(elementoACambiar.id)] = index

        if(index + siguientePosicion === posicionDelEncimado){
          nuevoElementosGrid[posicionDelEncimado] = elementoAgarrado
          this.elementosGrid = nuevoElementosGrid
          this.posicionId[elementoAgarradoID] = posicionDelEncimado
        }
      }
    },
    // el buscador sirve para hacer la busqueda del elemento al que le queremos despues sacar la opacity o ponersela, ya que queremos que sea el padre y no a uno de sus hijos
    buscarElementoEnEvento({padre, hijo, evento}){
      let elementoEncontrado
      if(evento.target.matches("." + padre)){
        elementoEncontrado = evento.target
      }
      else if(evento.target.matches("." + hijo) ){
        elementoEncontrado = evento.target.parentElement
      }
      else if(evento.target.parentElement.matches("." + hijo)){
        elementoEncontrado = evento.target.parentElement.parentElement
      }
      else{
        const elementos = evento.path.slice(2, evento.path.length - 1)
        elementoEncontrado = elementos.find(el => el.matches("." + padre))
      }

      return elementoEncontrado
    }
  },
  mounted(){
    this.products.forEach((el, index) => {
      const posicionEl = this.posicionId[el.id]
      this.elementosGrid[posicionEl] = el
      if(index === this.products.length - 1){
        const idVacio = "vacio1"
        this.posicionId[idVacio] = 8
        const posicionElVacio = this.posicionId[idVacio]
        this.elementosGrid[8] = {
          id: idVacio,
          espacio: true,
          name: "vacio1",
          percent: "vacio"
        }
      }
    })
  },
  created(){
    document.addEventListener("mouseover", (event)=>{
      if(this.isDrag) return
      this.mouseOver(event)
    })
    document.addEventListener("drag", this.mouseOver)
    document.addEventListener("dragover", this.dragOver)
    document.addEventListener("dragleave", this.dragLeave)
    document.addEventListener("drop", this.drop)
  },
  computed: {
    ...mapState(["token", "server"]),
  },
};
</script>

<style scoped>
.dashboard-graficas{
    display: flex;
    height: 100vh;   
    padding-top: 65px;
}
.container {
    margin: 100px auto;
    max-width: 70%;
    overflow-x: auto;
    padding: 1em;
}

.container-cards-percent{
    display: flex;
    gap: 1em;
}

.container-grid-movible{
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  transition: all 1s;  background: aliceblue;
  gap: 6px;
}

.subgrid-movible{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: aliceblue;
  transition: all .3s linear;
  cursor: pointer;
  border: 1px solid var(--bs-body-color);
  border-radius: 20px;
}
.subgrid-movible-clon{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: aliceblue;
  transition: all 1s linear;
  cursor: pointer;
  border: 1px solid var(--bs-body-color);
  border-radius: 20px;
}
.elemento-subgrid-movible{
  font-size: 12px;
  width: 90%;
  animation: fade .5s forwards;
  transition: all 1s ease;
  position: relative;

}

.elemento-subgrid-movible::before{
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  content: "";
  z-index: 0;
}

.elemento-subgrid-movible-clon{
  font-size: 12px;
  width: 90%;
  transition: all 1s ease;
}

body{
  overflow-x: hidden;
}
#root{
  overflow-x: hidden;
}

.bg-grey{
  /* opacity: 1; */
  /* opacity: 0; */
  position: relative;
}

.bg-grey::after{
  pointer-events: none;
  background-color: #2125291a;
  border-radius: 15px;
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  border: 2px dashed #21252948;
}
.subgrid-espacio{
  border: none;
}
</style>